import {Link} from "@inertiajs/react";
import {
  Button,
  Menu,
  Text,
  UnstyledButton
} from "@mantine/core";
import {PropsWithChildren} from "react";

import "./../style.css";

export default function Guest({ children }: PropsWithChildren) {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <div
        className={
          "w-full bg-white min-h-[110px] flex flex-col sm:flex-row justify-between items-center px-4"
        }
      >
        <div>
          <a href="https://profilii.hu">
            <div
              style={{
                backgroundImage:
                  "url(https://profilii.hu/wp-content/themes/profilii/includes/img/pro_filii_logo.png)",
              }}
              className="w-[241px] h-[60px] bg-contain bg-no-repeat"
            ></div>
          </a>
        </div>
        <div className={"flex w-full gap-4 justify-around flex-wrap"}>
          <Menu position="bottom-start">
            <Menu.Target>
              <UnstyledButton
                className="!text-brand-700 uppercase !text-xl font-bold hover:text-[#0a58ca] !hover:bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                Rólunk
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown className="!bg-brand-700">
              <Menu.Item
                className="!text-white uppercase !text-[20px] font-bold data-[hovered=true]:!bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <a href="https://profilii.hu/#bemutatkozas/">Alapítvány</a>
              </Menu.Item>
              <Menu.Item
                className="!text-white uppercase !text-xl font-bold data-[hovered=true]:!bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <a href="https://profilii.hu/#kuratorium">Kuratórium</a>
              </Menu.Item>
              <Menu.Item
                className="!text-white uppercase !text-xl font-bold data-[hovered=true]:!bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <a href="https://profilii.hu/#biralobizottsag">
                  Bírálóbizottság
                </a>
              </Menu.Item>
              <Menu.Item
                className="!text-white uppercase !text-xl font-bold data-[hovered=true]:!bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <a href="https://profilii.hu/a-meszaros-csoportrol/">
                  Mészáros csoport
                </a>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Menu position="bottom-start">
            <Menu.Target>
              <UnstyledButton
                className="!text-brand-700 uppercase !text-xl font-bold hover:text-[#0a58ca]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                Pályázóknak
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown className="!bg-brand-700 ">
              <Menu.Item
                className="!text-white uppercase !text-[20px] font-bold data-[hovered=true]:!bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <a href="https://profilii.hu/palyazati-kiiras/">
                  Pályázati kiírás
                </a>
              </Menu.Item>
              <Menu.Item
                className="!text-white uppercase !text-xl font-bold data-[hovered=true]:!bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <a href="https://profilii.hu/regisztracio/">Regisztráció</a>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Menu>
            <Menu.Target>
              <UnstyledButton
                className="!text-brand-700 uppercase !text-xl font-bold hover:text-[#0a58ca]"
                style={{
                  fontFamily: "Darker Grotesque",
                  fontSize: "20px !important",
                }}
              >
                <a href="https://profilii.hu/hirek/">Hírek</a>
              </UnstyledButton>
            </Menu.Target>
          </Menu>
          <Menu>
            <Menu.Target>
              <UnstyledButton
                className="!text-brand-700 uppercase !text-xl font-bold hover:text-[#0a58ca]"
                style={{
                  fontFamily: "Darker Grotesque",
                  fontSize: "20px !important",
                }}
              >
                <a href="https://profilii.hu/gyik/">GYIK</a>
              </UnstyledButton>
            </Menu.Target>
          </Menu>
          <Menu position="bottom-start">
            <Menu.Target>
              <UnstyledButton
                className="!text-brand-700 uppercase !text-xl font-bold hover:text-[#0a58ca]"
                style={{
                  fontFamily: "Darker Grotesque",
                  fontSize: "20px !important",
                }}
              >
                Nyertesek
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown className="!bg-brand-700 ">
              <Menu.Item
                className="!text-white uppercase !text-[20px] font-bold data-[hovered=true]:!bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <Menu trigger="hover" position="right">
                  <Menu.Target>
                    <Menu.Item
                      className="!text-white uppercase !text-[20px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      2022
                    </Menu.Item>
                  </Menu.Target>
                  <Menu.Dropdown className="!bg-brand-700">
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#1magan">
                        I. pályázati ciklus – Támogatott magán pályázatok
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#1szervezet">
                        I. pályázati ciklus – Támogatott szervezeti pályázatok
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#2magan">
                        II. pályázati ciklus – Támogatott magán pályázatok
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#2szervezet">
                        II. pályázati ciklus – Támogatott szervezeti pályázatok
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#3magan">
                        III. pályázati ciklus – Támogatott magán pályázatok
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#3szervezet">
                        III. pályázati ciklus – Támogatott szervezeti pályázatok
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#4magan">
                        IV. pályázati ciklus – Támogatott magán pályázatok
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#4szervezet">
                        IV. pályázati ciklus – Támogatott szervezeti pályázatok
                      </a>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Menu.Item>
              <Menu.Item
                className="!text-white uppercase !text-[20px] font-bold data-[hovered=true]:!bg-[#464081]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <Menu trigger="hover" position="right">
                  <Menu.Target>
                    <Menu.Item
                      className="!text-white uppercase !text-[20px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      2023
                    </Menu.Item>
                  </Menu.Target>
                  <Menu.Dropdown className="!bg-brand-700">
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#5magan">
                        I. pályázati ciklus – Támogatott magán pályázatok
                      </a>
                    </Menu.Item>
                    <Menu.Item
                      className="!text-white !text-[15px] font-bold data-[hovered=true]:!bg-[#464081]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      <a href="https://profilii.hu/nyertesek/#5szervezet">
                        I. pályázati ciklus – Támogatott szervezeti pályázatok
                      </a>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>

          <Menu>
            <Menu.Target>
              <UnstyledButton
                className="!text-brand-700 uppercase !text-xl font-bold hover:text-[#0a58ca]"
                style={{
                  fontFamily: "Darker Grotesque",
                }}
              >
                <a href="https://profilii.hu/#">Keresés</a>
              </UnstyledButton>
            </Menu.Target>
          </Menu>
        </div>
        <div className={"flex justify-end sm:w-1/5"}>
          <Button className="!w-full !h-[46px} !bg-brand-700">
            <Text className=" uppercase !font-bold hover:text-[#0a58ca]">
              Belépés
            </Text>
          </Button>
        </div>
      </div>
      <div className={"flex flex-col sm:justify-center items-center pt-6 sm:pt-0 grow sm:mb-[110px]"}>
        <div className="cursor-pointer text-center mt-6 px-6 py-4">
          <Link href={route('password.request')}
                className="hover:bg-brand w-full sm:max-w-md p-3 bg-brand-600 items-center text-indigo-100 leading-none sm:rounded-lg flex lg:inline-flex"
                role="alert">
            <svg className="fill-current w-4 h-4 mr-3 shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
            </svg>
            <span
              className="font-semibold mr-2 text-left flex-auto">
            Amennyiben rendelkezik korábban regisztrált fiókkal, kérjük, igényeljen új jelszót a bejelentkezéshez az 'Elfelejtett jelszó?' linkre kattintva.
          </span>
            <svg className=" shrink-0 fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
            </svg>
          </Link>
        </div>

        <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
          {children}
        </div>

        <div
          onClick={() => window.location.replace("https://profilii.hu/")}
          className="cursor-pointer mt-6 underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-700"
        >
          Vissza a profilii.hu oldalra
        </div>
      </div>

    </div>
  );
}
